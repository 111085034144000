/* Resize -- fits page to the window and resizes elements as needed */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {

  var module = {};

  var content;
  var contentPadding = 0;

  function getBorderHeight(element) {
    return element.outerHeight() - element.innerHeight();
  }

  function getBorderWidth(element) {
    return element.outerWidth() - element.innerWidth();
  }

  module.fitToWindow = function () {
    content = $('#content');
    contentPadding = content.innerHeight() - content.height();
    var newContentHeight = $('#wrapper').innerHeight() - $('#toolbar').outerHeight(true) - contentPadding;
    content.height(newContentHeight);
  };

  module.analysisPage = function () {
    module.fitToWindow();

    var map = $('#bvalue_map');
    var searchForm = $('#b_value_search_form');
    var searchFormWrapper = $('#search_form_wrapper');
    var mapBorder = getBorderHeight(map);
    var contentHeight = content.height() - contentPadding;
    var leftColumn = $('#bvalue_left_column');
    var searchFormHeight = searchFormWrapper.length > 0 ? searchFormWrapper.height() : 0;

    map.height(contentHeight - searchFormHeight - mapBorder);
    map.width(leftColumn.width() - mapBorder);

    var plotWrapper = $('#plot_wrapper');
    var plotWrapperBorder = getBorderHeight(plotWrapper);

    var histogramWrapper = $('#histogram_wrapper');
    var histogramWrapperHeight = searchForm.length > 0 ? searchForm.height() : histogramWrapper.css('min-height');
    histogramWrapper.height(histogramWrapperHeight);
    plotWrapper.height(contentHeight - histogramWrapper.outerHeight(true) - plotWrapperBorder);

    var plot = $('#plot');
    plot.height(plotWrapper.height() - $('#plot_summary').height() - 20);
    Histogram.resizeHistogramContainer();

    if ($("#bvalue_plot > *").length > 0) {
      bvalueChart.updateChart();
    }
  };

  //The searchPage is still being used by the station map page.
  module.searchPage = function () {
    module.fitToWindow();

    var searchForm = $('#search_form');
    var eventList = $('#event_list');
    var map = $('#map');
    var contentHeight = content.height() - contentPadding;
    var searchFormHeight = searchForm.length ? $('#search_form_wrapper').height() : 0;
    var eventListWidth = eventList.length ? eventList.outerWidth(true) : 0;
    var eventListBorder = getBorderHeight(eventList);
    var eventListHeight = searchForm.length ? contentHeight - searchFormHeight - eventListBorder : contentHeight - eventListBorder;
    var mapBorder = getBorderHeight(map);
    var mapHeight = contentHeight - mapBorder;
    var mapWrapper = $('#map_wrapper');
    mapWrapper.css("marginLeft", eventListWidth + "px");
    if (map.length) {
      map.height(mapHeight);
    }
    if (eventList.length) {
      var eventListTable = $('table.listing');
      var eventListTableBody = eventListTable.find('tbody');
      var eventListTableHead = eventListTable.find('thead');
      var eventListHeaderHeight = eventListTableHead.height() + $('#event_list .header_table').height();
      eventListTableBody.height(eventListHeight - eventListHeaderHeight);
      eventList.height(eventListHeight);

      var eventListBodyCells = eventListTableBody.find('tr.data:first').children();
      var eventListHeadCells = eventListTable.find('thead tr').children();
      var expandableHeadCell, expandableBodyCell;

      eventListHeadCells.each(function (index, headCell) {
        var bodyCell = eventListBodyCells.eq(index);
        var bodyCellWidth = bodyCell.width() + getBorderWidth(bodyCell);
        var headCellWidth = $(headCell).width();
        if ($(headCell).hasClass('expandable')) {
          expandableBodyCell = bodyCell;
          expandableHeadCell = $(headCell);
        }
        else if (headCellWidth > bodyCellWidth) {
          bodyCell.width(headCellWidth);
          eventListTableBody.find('tr.data td:nth-child(' + (index + 1) + ')').width(headCellWidth);
        }
        else {
          $(headCell).width(bodyCellWidth);
        }
      });

      if (expandableBodyCell && expandableHeadCell) {
        //The browser automatically tries to resize the other table cells after setting the body cell width.
        //It then resizes the original cell, so we have to get the cell width a second time to get the actual final value.
        var actualExpandedCellWidth = expandableBodyCell.width();
        expandableBodyCell.width(actualExpandedCellWidth);
        actualExpandedCellWidth = expandableBodyCell.width();
        expandableHeadCell.width(actualExpandedCellWidth);
      }
    }
  };

  window.addEventListener('load', function () {
    window.onresize ? $(window).trigger('resize') : module.fitToWindow();
  });

  return module;
}));