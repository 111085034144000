/* ThresholdStatus */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {

  var BANNER_DIV_ID = "threshold_status_banner";
  var POPUP_DIV_ID = "threshold_status_popup";

  function ThresholdControl(bannerThresholdsPath) {
    this.bannerThresholdsPath = bannerThresholdsPath;
  }

  var module = {};

  function updateThresholdBanner(thresholdControl) {
    var thresholdStatusBanner = Util.getElementById(BANNER_DIV_ID);
    $.get(thresholdControl.bannerThresholdsPath, function(banner) {
      if (banner !== null) {
        thresholdStatusBanner.replaceWith(banner);
      } else {
        thresholdStatusBanner.replaceWith(Util.createElement('div').attr('id', BANNER_DIV_ID));
      }
    })
  }

  function updateThreshold(thresholdControl) {
    updateThresholdBanner(thresholdControl);
  }

  module.setup = function(bannerThresholdsPath, refreshIntervalInMilliseconds) {
    var thresholdControl = new ThresholdControl(bannerThresholdsPath);
    updateThreshold(thresholdControl);
    setInterval(updateThreshold, refreshIntervalInMilliseconds, thresholdControl);
  };

  module.showPopup = function(popupPath, redirectPath) {
    var thresholdPopup = Util.getElementById(POPUP_DIV_ID);
    $.get(popupPath, function(popupContents) {
      if (popupContents == null) {
        return;
      }
      thresholdPopup.html(popupContents);
      var windowSelector = $(window);
      thresholdPopup.dialog({
        width: windowSelector.width() * 0.8,
        height: windowSelector.height() * 0.8,
        buttons: [
          {
            text: I18n.t("risk_managements.popup.more_detail"),
            click: function() {
              window.location.href = Util.makeAbsolute(redirectPath);
            }
          },
          {
            text: I18n.t("globals.close"),
            click: function() {
              thresholdPopup.dialog("close");
              window.popupSeismicityChart = null;
            }
          }
        ],
        closeText: I18n.t("globals.close"),
        resize: window.popupSeismicityChart.resize
      });
    });
  };

  return module;
}));