/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

Popup = require('../javascripts/popup.js');
Util = require('../javascripts/util.js');
DatetimeRange = require('../javascripts/datetime-range.js');
Resize = require('../javascripts/resize.js');
ThresholdStatus = require('../javascripts/threshold-status.js');
Form = require('../javascripts/form.js');

require('../javascripts/set-moment-locale.js');

D3Util = require('../javascripts/d3-util.js');
Nvd3Util = require('../javascripts/nvd3-util.js');
